import { Button, Divider, Table } from 'antd';
import React, { useEffect, useCallback, useState } from 'react';
import toast from 'react-hot-toast';
// import { DateTime } from 'luxon';
import { Layout } from '../../components/Layout';
import AddOfficeModal from '../../components/Modal/AddOfficeModal';
import DeleteOfficeModal from '../../components/Modal/DeleteOfficeModal';
import SelectionModeratorModal from '../../components/Modal/SelectionOfficeModal';
import { getModeratorsRequest } from '../../models/moderators/moderators';
import {
  createOfficeRequest,
  deleteOfficeRequest,
  getOfficeRequest,
  getOfficesAccessRequest,
  getAllOfficesDailyDataRequest,
} from '../../models/office/office';
import { IconEdit, IconHome, IconTrash } from '../../utils/Icons';

const Office = () => {
  const [isModalAddOffice, setIsModalAddOffice] = useState(false);
  const [isModalDeleteOffice, setIsModalDeleteOffice] = useState(false);
  const [isModalEditModerator, setIsModalEditModerator] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState(undefined);
  const [mappedDataOffice, setMappedDataOffice] = useState([]);

  const [moderatorsList, setModeratorsList] = useState(null);
  const [officesAccessList, setOfficesAccessList] = useState(null);
  const [choosedOfficesForDelete, setChoosedOfficesForDelete] = useState(null);
  const [officesDailyData, setOfficesDailyData] = useState(null);

  const [formAddOffice, setFormAddOffice] = useState({
    name: '',
    moderator: '',
    plan: null,
  });

  const showModalAddOffice = () => {
    setIsModalAddOffice(true);
  };

  const showModalDeleteOffice = (record) => {
    setChoosedOfficesForDelete([record.key]);
    setIsModalDeleteOffice(true);
  };

  const showModalEditModerator = (record) => {
    setSelectedOffice(record.key);
    setIsModalEditModerator(true);
  };

  const handleCancelAddOffice = () => {
    setIsModalAddOffice(false);
  };

  const handleCancelDeleteOffice = () => {
    setChoosedOfficesForDelete(null);
    setIsModalDeleteOffice(false);
  };

  const handleSubmitDeleteOffice = () => {
    if (!choosedOfficesForDelete) return;
    choosedOfficesForDelete?.forEach((office) => {
      deleteOfficeRequest(office)
        .then(() => {
          setIsModalDeleteOffice(false);
          setMappedDataOffice((prev) =>
            prev.filter((item) => item.key !== office),
          );
        })
        .catch((err) => {
          console.warn('Delete office (deleteOfficeRequest) error', err);

          // Check if the error is an Axios error to get a more descriptive message
          const errorMessage = err.response
            ? err.response.data.message || 'Delete office error'
            : 'Network error';

          // Show error toast
          toast.error(errorMessage);
        });
    });
  };

  const handleCancelEditModerator = () => {
    setSelectedOffice(null);
    setIsModalEditModerator(false);
  };

  const handleAddOffice = () => {
    const newEntry = {
      key: String(mappedDataOffice.length + 1),
      ...formAddOffice,
    };

    if (!newEntry.name)
      return console.error("You don't set name of the office!");
    createOfficeRequest(newEntry.name)
      .then((r) => {
        // setMappedDataOffice((prev) => [...prev, newEntry]);
        fetchOffices();
      })
      .catch((err) => {
        console.warn('Error createOfficeRequest', err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data.message || 'Create office error'
          : 'Network error';

        // Show error toast
        toast.error(errorMessage);
      });
  };

  const columns = [
    {
      title: `Название офиса`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `Модератор`,
      dataIndex: 'moderator',
      key: 'moderator',
      render: (moderator, record) => (
        <Button type="text" onClick={() => showModalEditModerator(record)}>
          {moderator || 'Укажите модератора'}
          <IconEdit width="20" height="20" fill="#00c39a" />
        </Button>
      ),
    },
    {
      title: `Общее кол-во людей`,
      dataIndex: 'total-people',
      key: 'total-people',
      render: (_, record) => {
        if (!officesDailyData) return '-';
        return officesDailyData
          ?.flat()
          ?.filter((data) => data?.OfficeId === record.key)
          ?.reduce((acc, current) => {
            return acc + Number(current.LinePeopleCount) || 0;
          }, 0);

        // return `${
        //   officesDailyData
        //     ? officesDailyData?.find((data) => data?.OfficeId === record.key)
        //         ?.LinePeopleCount || 0 // or OfficePeopleCount
        //     : 0
        // }`;
      },
    },
    {
      title: `План`,
      dataIndex: 'plan',
      key: 'plan',
      render: (_, record) => {
        if (!officesDailyData) return '-';
        return `${officesDailyData
          ?.flat()
          ?.filter((data) => data?.OfficeId === record.key)
          ?.reduce((acc, current) => {
            return acc + Number(current.PlannedUsdt) || 0;
          }, 0)}$`;
      },
    },
    {
      title: `Действия`,
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Button type="text" onClick={() => showModalDeleteOffice(record)}>
          <IconTrash width="20" height="20" fill="#d94c48" />
        </Button>
      ),
      width: '5%',
    },
  ];

  // fetch access (moderators) for each office
  const fetchOfficesAccessData = useCallback(async (data) => {
    if (!data) return;
    const accessDataOffices = await Promise.all(
      data.map(async (office) => {
        if (!office.key) return;

        try {
          const { data } = await getOfficesAccessRequest(office.key);

          return data;
        } catch (err) {
          console.error('getOfficesAccessRequest error: ', err);
        }
      }),
    );

    setOfficesAccessList(accessDataOffices.flat());
  }, []);

  // fetch plan for each office
  const fetchOfficesDailyData = useCallback(async (data) => {
    // Get the start of the current week (Monday as the start of the week)
    // const startOfCurrentWeek = DateTime.now()
    //   .startOf('week')
    //   .toFormat('yyyy-MM-dd');

    // const startCurrentWeekFormated = DateTime.fromISO(
    //   startOfCurrentWeek,
    // ).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");

    if (!data) return;

    const dailyDataOffices = await Promise.all(
      data.map(async (office) => {
        try {
          const { data } = await getAllOfficesDailyDataRequest(office.key);

          // const startCurrentWeekPlan = data.DailyData.find(
          //   (plan) => plan.Day === startCurrentWeekFormated,
          // );

          // return startCurrentWeekPlan;
          return data.DailyData;
        } catch (err) {
          console.error('getAllOfficesDailyDataRequest error: ', err);
        }
      }),
    );

    setOfficesDailyData(dailyDataOffices);
  }, []);

  const fetchOffices = useCallback(() => {
    getOfficeRequest().then(({ data }) => {
      // get all offices access
      if (!data) return;
      const formatedResponse = data.map((item) => {
        console.log('item', item);
        return {
          key: item.ID,
          name: item.Name,
          moderator: null,
          plan: 0,
        };
      });
      setMappedDataOffice(formatedResponse);

      fetchOfficesAccessData(formatedResponse);
      fetchOfficesDailyData(formatedResponse);
    });
  }, [fetchOfficesAccessData, fetchOfficesDailyData]);

  useEffect(() => {
    fetchOffices();
  }, [fetchOffices]);

  useEffect(() => {
    // get all moderators
    getModeratorsRequest().then(({ data }) => {
      setModeratorsList(data);
    });
  }, []);

  const mappedDataOfficeFormated = mappedDataOffice?.map((office) => {
    const findOfficeAccess = officesAccessList?.find(
      (access) => office.key === access?.OfficeId,
    );
    if (!findOfficeAccess) return office;
    console.log('office', office);
    return {
      ...office,
      moderator: moderatorsList
        ? moderatorsList.find(
            (moderator) => findOfficeAccess.UserId === moderator.ID,
          )?.Login
        : null,
    };
  });

  return (
    <Layout section={'admin'}>
      <div className="box">
        <div className={`office`}>
          <h4 className="title">
            <span>
              <IconHome width="25" height="25" fill="#00c39a" />
              Офиса
            </span>

            <Button
              htmlType="button"
              onClick={showModalAddOffice}
              className="xs"
            >
              Добавить Офис
            </Button>
          </h4>

          <Divider />

          <Table
            tableLayout="auto"
            dataSource={mappedDataOfficeFormated}
            columns={columns}
            scroll={{ x: 768 }}
          />
        </div>

        {/* Modal Delete Office */}
        <DeleteOfficeModal
          isModalOpen={isModalDeleteOffice}
          officesList={mappedDataOffice}
          choosedOfficesForDelete={choosedOfficesForDelete}
          handleCancel={handleCancelDeleteOffice}
          handleSubmit={handleSubmitDeleteOffice}
        />

        {/* Modal Edit Moderator */}
        <SelectionModeratorModal
          isModalOpen={isModalEditModerator}
          selectedOffice={selectedOffice}
          fetchOffices={fetchOffices}
          handleCancel={handleCancelEditModerator}
        />

        {/* Modal Add Office */}
        <AddOfficeModal
          isModalOpen={isModalAddOffice}
          handleAddData={handleAddOffice}
          handleCancel={handleCancelAddOffice}
          setFormAddOffice={setFormAddOffice}
        />
      </div>
    </Layout>
  );
};

export default Office;
