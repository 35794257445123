import React from 'react';
import { DateTime } from 'luxon';
import ReactApexChart from 'react-apexcharts';

const groupAndSumByDate = (data) => {
  const result = data.reduce((acc, item) => {
    const existing = acc.find((entry) => entry.date === item.date);

    if (existing) {
      existing.peopleCount += item.peopleCount;
    } else {
      acc.push({ date: item.date, peopleCount: item.peopleCount });
    }

    return acc;
  }, []);

  return result;
};

const PeopleChart = ({ officesWeekData }) => {
  if (!officesWeekData) return;
  officesWeekData.sort((a, b) => {
    return (
      DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis()
    );
  });

  const formatedOfficesWeekData = groupAndSumByDate(officesWeekData);

  const peopleChartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: formatedOfficesWeekData.map((data) =>
        DateTime.fromISO(data.date).toFormat('yyyy-MM-dd'),
      ),
    },
    yaxis: {
      title: {
        text: 'Количество людей',
      },
      labels: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    markers: {
      size: 4,
    },
    colors: ['#00c39a'],
    legend: {
      position: 'top',
    },
  };

  const peopleChartSeries = [
    {
      name: 'Количество людей',
      data: formatedOfficesWeekData.map((data) => data.peopleCount),
    },
  ];

  return (
    <ReactApexChart
      options={peopleChartOptions}
      series={peopleChartSeries}
      type="line"
      height={350}
    />
  );
};

export default PeopleChart;
