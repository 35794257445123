import React from "react";

const IconUserCheck = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill={fill}
          opacity={0.3}
          d="M17.235,14.26A5.244,5.244,0,0,0,13,12.5H9c-4.06,0-5.5,2.97-5.5,5.52C3.5,20.3,4.71,21.5,7,21.5h7.24a.3.3,0,0,0,.257-.448,4.5,4.5,0,0,1,2.579-6.325A.3.3,0,0,0,17.235,14.26Z"
        />
        <circle fill={fill} cx="11.009" cy="6.5" r="4" />
        <path
          fill={fill}
          d="M18.333,21.417a.748.748,0,0,1-.53-.22L16.47,19.864A.75.75,0,0,1,17.53,18.8l.8.8L20.47,17.47a.75.75,0,0,1,1.06,1.06L18.863,21.2A.748.748,0,0,1,18.333,21.417Z"
        />
      </g>
    </svg>
  );
};

export default IconUserCheck;
