import axios from 'axios';
import Cookies from 'universal-cookie';
import { apiDomain } from '../../config/consts';

export const createOfficeRequest = async (Name) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  if (Name) {
    return axios.post(
      `${apiDomain}/create-office`,
      {
        Name,
      },
      {
        headers: {
          Authorization: `Bearer ${atToken}`,
        },
      },
    );
  } else {
    console.error('Login form: some of reuired data is missed.');
  }
};

export const setOfficePlanRequest = async (OfficeId, Day, PlannedUsdt) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  if (OfficeId && Day && PlannedUsdt) {
    return axios.post(
      `${apiDomain}/set-office-plan`,
      {
        OfficeId,
        Day,
        PlannedUsdt,
      },
      {
        headers: {
          Authorization: `Bearer ${atToken}`,
        },
      },
    );
  } else {
    console.error('Set office plan: some of reuired data is missed.');
  }
};

export const connectModeratorWithOfficeRequest = async (
  OfficeId,
  ModeratorId,
) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  if (OfficeId && ModeratorId) {
    return axios.post(
      `${apiDomain}/connect-moderator-with-office`,
      {
        OfficeId,
        ModeratorId,
      },
      {
        headers: {
          Authorization: `Bearer ${atToken}`,
        },
      },
    );
  } else {
    console.error(
      'Connect moderator with office: some of reuired data is missed.',
    );
  }
};

export const getOfficeRequest = async () => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  return axios.get(`${apiDomain}/offices`, {
    headers: {
      Authorization: `Bearer ${atToken}`,
    },
  });
};

export const getOfficesAccessRequest = async (officeId) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  return axios.get(`${apiDomain}/office-access/${officeId}`, {
    headers: {
      Authorization: `Bearer ${atToken}`,
    },
  });
};

export const getOfficesDailyDataRequest = async (officeId, date) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  return axios.get(`${apiDomain}/office-daily-data/${officeId}/${date}`, {
    headers: {
      Authorization: `Bearer ${atToken}`,
    },
  });
};

export const getAllOfficesDailyDataRequest = async (officeId) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  return axios.get(`${apiDomain}/all-office-daily-data/${officeId}`, {
    headers: {
      Authorization: `Bearer ${atToken}`,
    },
  });
};

export const deleteOfficeRequest = async (id) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  if (id) {
    return axios.delete(
      `${apiDomain}/office/${id}`,
      // null,
      {
        headers: {
          Authorization: `Bearer ${atToken}`,
        },
      },
    );
  } else {
    console.error('Delete office: some of reuired data is missed.');
  }
};

export const updateOfficeDataRequest = async (
  OfficeId,
  Day,
  WithdrawnUsdt,
  OfficePeopleCount,
  LinePeopleCount,
  EarnedUsdt,
  EarnedRubles,
) => {
  const cookies = new Cookies();
  const atToken = cookies.get('at');

  if (!OfficeId || !Day)
    return console.error(
      'updateOfficeDataRequest error: some of required data is missed!',
    );

  const payload = {
    OfficeId,
    Day,
  };

  // Add optional fields only if they are defined
  if (WithdrawnUsdt !== undefined) payload.WithdrawnUsdt = WithdrawnUsdt;
  if (OfficePeopleCount !== undefined)
    payload.OfficePeopleCount = OfficePeopleCount;
  if (EarnedUsdt !== undefined) payload.EarnedUsdt = EarnedUsdt;
  if (EarnedRubles !== undefined) payload.EarnedRubles = EarnedRubles;
  if (LinePeopleCount !== undefined) payload.LinePeopleCount = LinePeopleCount;

  return axios.patch(`${apiDomain}/update-office-data`, payload, {
    headers: {
      Authorization: `Bearer ${atToken}`,
    },
  });
};
