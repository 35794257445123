import React from 'react';
import { DateTime } from 'luxon';
import ReactApexChart from 'react-apexcharts';

const ResultChart = ({ officesWeekData }) => {
  if (!officesWeekData) return;
  officesWeekData.sort((a, b) => {
    return (
      DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis()
    );
  });

  const xaxis = officesWeekData?.map((data) =>
    DateTime.fromISO(data.date).toFormat('yyyy-MM-dd'),
  );

  const resultChartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: xaxis,
    },
    yaxis: {
      title: {
        text: 'Результаты',
      },
      labels: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    markers: {
      size: 4,
    },
    colors: ['#00c39a', '#008FFB'],
    legend: {
      position: 'top',
    },
  };

  // officesWeekData.sort((a, b) => a.key - b.key);

  const resultChartSeries = [
    {
      name: 'Результат в USDT',
      data: officesWeekData.map((data) => data.resultUSDT),
    },
    {
      name: 'Результат в RUB',
      data: officesWeekData.map((data) => data.resultRUB),
    },
  ];

  return (
    <ReactApexChart
      options={resultChartOptions}
      series={resultChartSeries}
      type="line"
      height={350}
    />
  );
};

export default ResultChart;
