import React from 'react';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { DatePicker, Form } from 'antd';
import {
  IconAngleLeft,
  IconAngleRight,
  IconAnglesLeft,
  IconAnglesRight,
  IconCalendar,
  IconTimesSquare,
} from '../../../utils/Icons';

const DatePickerUi = ({
  name,
  id,
  label,
  defaultDate,
  format,
  errorMess,
  ...props
}) => {
  const dd = defaultDate
    ? DateTime.fromISO(defaultDate).toISODate()
    : undefined;

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: true, message: errorMess }]}
    >
      <DatePicker
        id={id}
        defaultValue={dd ? dayjs(dd) : undefined}
        format={format}
        suffixIcon={<IconCalendar width="20" height="20" fill="#00c39a" />}
        clearIcon={<IconTimesSquare width="20" height="20" fill="#d94c48" />}
        nextIcon={<IconAngleRight width="17" height="17" fill="#fff" />}
        prevIcon={<IconAngleLeft width="17" height="17" fill="#fff" />}
        superNextIcon={<IconAnglesRight width="17" height="17" fill="#fff" />}
        superPrevIcon={<IconAnglesLeft width="17" height="17" fill="#fff" />}
        {...props}
      />
    </Form.Item>
  );
};

export default DatePickerUi;
