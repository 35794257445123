import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Form, Modal, Select } from 'antd';
import { SelectUi } from '../ui';
import { IconClose } from '../../utils/Icons';
import { getModeratorsRequest } from '../../models/moderators/moderators';
import { connectModeratorWithOfficeRequest } from '../../models/office/office';
import { unconnectModeratorWithOfficeRequest } from '../../models/moderators/moderators';
import { getOfficesAccessRequest } from '../../models/office/office';

const { Option } = Select;

const SelectionModeratorModal = ({
  isModalOpen,
  selectedOffice,
  fetchOffices,
  handleCancel,
}) => {
  const [form] = Form.useForm();

  const [moderatorsList, setModeratorsList] = useState(null);

  useEffect(() => {
    // get all moderators
    getModeratorsRequest().then(({ data }) => {
      setModeratorsList(data);
    });
  }, []);

  const successForm = () => {
    const moderatorId = Number(form.getFieldValue('moderator'));
    if (!moderatorId || !selectedOffice)
      return console.error('moderatorId or selectedOffice is missed!');

    // unconnect all moderators from office before add another moderator ubove
    getOfficesAccessRequest(selectedOffice)
      .then(({ data }) => {
        if (!data?.length) return;
        data.forEach((access) => {
          unconnectModeratorWithOfficeRequest(access.OfficeId, access.UserId)
            .then(() => {
              console.log(
                `Successfully unconnected id: ${access.UserId} from office id: ${access.OfficeId}`,
              );
            })
            .catch((err) => {
              console.warn('unconnectModeratorWithOfficeRequest error', err);
            });
        });
      })
      .catch((err) => console.warn('getOfficesAccessRequest error', err));

    // connect new moderator to the office
    connectModeratorWithOfficeRequest(selectedOffice, moderatorId)
      .then(() => {
        fetchOffices();
        form.resetFields();
      })
      .catch((err) => {
        console.warn('Error connectModeratorWithOfficeRequest', err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data.message || 'Connect moderator with office error'
          : 'Network error';

        // Show error toast
        toast.error(errorMessage);
      });
  };

  const errorForm = () => {
    console.error('Error');
  };

  return (
    <Modal
      title="Выбор Модератора"
      open={isModalOpen}
      onCancel={handleCancel}
      className="modal-closed"
      closeIcon={<IconClose width="21px" height="21px" fill="#d94c48" />}
      footer={null}
    >
      <Form
        form={form}
        name="addModerator"
        layout="vertical"
        onFinish={successForm}
        onFinishFailed={errorForm}
        autoComplete="off"
      >
        <SelectUi name={'moderator'} label={`Выберите модератора`}>
          {moderatorsList &&
            moderatorsList.map(({ ID, Login }, i) => (
              <Option value={ID} key={`moderator-${i}`}>
                {Login}
              </Option>
            ))}
        </SelectUi>

        <div className="flex justify-center gap-10 mt-30">
          <Form.Item shouldUpdate>
            {() => (
              <Button
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
                className="xs"
              >
                Добавить
              </Button>
            )}
          </Form.Item>
          <Button onClick={handleCancel} className="xs">
            Закрыть
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SelectionModeratorModal;
