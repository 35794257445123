import { Divider, Table } from 'antd';
import React, { useEffect, useCallback, useState } from 'react';
import { DateTime } from 'luxon';
import { Layout } from '../../components/Layout';
import { IconUserTimes } from '../../utils/Icons';
import {
  getOfficeRequest,
  getAllOfficesDailyDataRequest,
} from '../../models/office/office';

const ClosedClients = () => {
  const [mappedDataClosed, setMappedDataClosed] = useState([]);

  const fetchClosedClientsData = useCallback(async (officesData) => {
    if (!officesData) return;
    // Create an array of promises for all requests
    const closedClientsPromises = officesData.map(async (office) => {
      try {
        const { data } = await getAllOfficesDailyDataRequest(office.ID);
        if (!data?.ClosedClients) return null;

        return data.ClosedClients.map((client) => {
          const date = data.DailyData.find(
            (dailyItem) => dailyItem.ID === client.OfficeDailyDataId,
          )?.Day;
          const officeId = data.DailyData.find(
            (dailyItem) => dailyItem.ID === client.OfficeDailyDataId,
          )?.OfficeId;

          return {
            key: client.ID,
            name:
              officesData.find((office) => office.ID === officeId)?.Name || '-',
            userName: client.Name,
            number: client.Number,
            date: date ? DateTime.fromISO(date).toFormat('yyyy-MM-dd') : '-',
          };
        });
      } catch (err) {
        return null;
      }
    });

    // Wait ending of all requests with Promise.all
    const allResults = await Promise.all(closedClientsPromises);

    // Filter null values (if some of requests doesn't received data)
    const filteredResults = allResults
      .filter((result) => result !== null)
      .flat();

    // Update state with collected data
    if (filteredResults) setMappedDataClosed(filteredResults);
  }, []);

  useEffect(() => {
    getOfficeRequest().then(({ data }) => {
      fetchClosedClientsData(data);
    });
  }, [fetchClosedClientsData]);

  const columns = [
    {
      title: `Название офиса`,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: `Имя клиента`,
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: `Номер телефона`,
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: `Дата`,
      dataIndex: 'date',
      key: 'date',
    },
  ];

  return (
    <Layout section={'admin'}>
      <div className="box">
        <div className={`closed`}>
          <h4 className="title">
            <span>
              <IconUserTimes width="25" height="25" fill="#00c39a" />
              Закрытые клиенты
            </span>
          </h4>

          <Divider />

          <Table
            tableLayout="auto"
            dataSource={mappedDataClosed ? mappedDataClosed : []}
            columns={columns}
            scroll={{ x: 768 }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ClosedClients;
