import React from 'react';
import { DateTime } from 'luxon';
import ReactApexChart from 'react-apexcharts';

const PeopleChart = ({ officesWeekData }) => {
  if (!officesWeekData) return;
  officesWeekData.sort((a, b) => {
    return (
      DateTime.fromISO(a.date).toMillis() - DateTime.fromISO(b.date).toMillis()
    );
  });

  const xaxis = officesWeekData?.map((data) =>
    DateTime.fromISO(data.date).toFormat('yyyy-MM-dd'),
  );
  // xaxis.sort((a, b) => {
  //   return DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis();
  // });

  const peopleChartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: xaxis,
    },
    yaxis: {
      title: {
        text: 'Количество людей',
      },
      labels: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    markers: {
      size: 4,
    },
    colors: ['#00c39a'],
    legend: {
      position: 'top',
    },
  };

  // officesWeekData.sort((a, b) => a.key - b.key);

  const peopleChartSeries = [
    {
      name: 'Количество людей',
      data: officesWeekData.map((data) => data.peopleCount),
    },
  ];

  return (
    <ReactApexChart
      options={peopleChartOptions}
      series={peopleChartSeries}
      type="line"
      height={350}
    />
  );
};

export default PeopleChart;
